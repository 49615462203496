<template>
  <component
    :is="transitionComponentType[props.transitionComponent]"
    :name="transitionClass"
    :mode="props.transitionMode"
    :appear="props.appear"
    class="animation"
  >
    <component :is="component" v-if="props.show" :key="props.show && props.transitionKey" :style="varCss">
      <slot></slot>
    </component>
  </component>
</template>

<script setup lang="ts">
import { computed, Transition, TransitionGroup } from 'vue';
import type { ISharedTransitionWrapper } from '~/components/SharedTransitionWrapper/SharedTransitionWrapper.types';

const props = withDefaults(defineProps<Partial<ISharedTransitionWrapper>>(), {
  appear: true,
  component: 'div',
  diagonal: () => {
    return {
      diagonalX: '20px',
      diagonalY: '20px',
      diagonalZ: '20px',
    };
  },
  duration: 0.25,
  positionType: 'static',
  ratio: 1,
  show: true,
  timingFunction: 'cubic-bezier(.66,.39,.21,.67)',
  transitionComponent: 'one',
  transitionKey: Date.now(),
  transitionMode: 'out-in',
  transitionName: 'opacity',
});

const transitionComponentType = {
  group: TransitionGroup,
  one: Transition,
};

const COMPONENT_NAME = 'transition_wrapper';
const transitionClass = computed(() => {
  return COMPONENT_NAME + '-' + props.transitionName;
});

const varCss = computed(() => {
  return {
    '--diagonal-x': props?.diagonal?.diagonalX,
    '--diagonal-y': props?.diagonal?.diagonalY,
    '--diagonal-z': props?.diagonal?.diagonalZ,
    '--timing-function': props.timingFunction,
    '--transition-delay': props.delay ? `${props.delay}s` : null,
    '--transition-duration': `${props!.duration * props!.ratio}s`,
    '--transition-position': props.positionType,
  };
});
</script>

<style src="./SharedTransitionWrapper.scss" scoped lang="scss"></style>
